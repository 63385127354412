// consumer_web_policyConfig_updateSubsidyPolicy
const __request = require(`./__request/__request_contentType_json`)
const consumer_web_policyConfig_updateSubsidyPolicy = (data) => {
  let pParameter = {
    method: 'post',
    urlSuffix: '/consumer/web/policyConfig/updateSubsidyPolicy',
    data,
  }
  return __request(pParameter)
}
export default consumer_web_policyConfig_updateSubsidyPolicy;
