import getPositionVo from "@/lib/data-service/haolv-default/consumer_web_policyConfig_getPositionVo";
import consumer_web_policyConfig_getPositionVoByBusinessType from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getPositionVoByBusinessType'
// import { ArrayTransferred, StringTransferred } from "@/lib/utils/common.js";
export default {
    name: 'positionLevelChoose',
    data() {
        return {
            activeBtn: null,
            // positionVoArr: [],

            positionIdList: [],
            type: "",
        }
    },
    props: {
        /*showType: {
            type: String,
            default: '' // add edit
        },*/
        positionVoArr: {
            type: Array,
            default: () => []
        }
    },
    components: {},
    created() {
    },
    mounted() {
        console.log('showType', this.showType)
        // this.showType === "edit" ? this.getDetail() : this._getPositionVo();
    },
    activated() {
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {},
    computed: {
        arrangePositionVo(type) {
            return function(type) {
                return this.getArrangePositionVo(type);
            };
        },
        positionArr: {
            get () {
                return this.positionVoArr
            },
            set (val) {
                this.$emit('update:positionVoArr', val)
            }
        }
    },
    filters: {},
    methods: {
        init(val) {
            console.log('val', val)
            /*
            * id: String 传入id
            * type: String 'edit': 编辑模式， 'add': 新增模式
            * businessType: '' 1:机票 2：火车票 3：酒店
            * */
            let type = val.type
            if (type) {
                this.type = type
            }
            let data = {}
            data.businessType = val.businessType
            if (this.type === 'edit') {
                data.id = val.id

            }
            this._getPositionVo(data)
        },
        // 获取职级
        async _getPositionVo(data) {
            let res = await consumer_web_policyConfig_getPositionVoByBusinessType(data);
            this.positionArr = res.datas;
        },
        // 更改职级显示
        getArrangePositionVo(type) {
            return this.positionArr.filter((item) => {
                if (type) {
                    return item.isEcho;
                } else {
                    return !item.isEcho;
                }
            });
        },
        // 删除职级
        deletePosition(val) {
            let positionIds = this.positionIdList
            this.positionArr.map((item) => {
                item.id === val.id ? (item.isEcho = false) : "";
            });
        },

        getRank(val) {
            this.activeBtn = null;
            let arr = [].concat(...val);
            this.$refs.cascaderAddr.checkedValue = [];
            this.getCheckedNodes(arr);
        },
        getCheckedNodes(arr) {
            let node = arr.map((id) => {
                return this.positionArr.forEach((item) => {
                    item.id === id ? (item.isEcho = true) : "";
                });
            });
        },
    }
}