import PositionLevelChoose from '@/component/positionLevelChoose/index.vue';
import consumer_web_policyConfig_getUsingCityTemplateList from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getUsingCityTemplateList'
import consumer_web_policyConfig_addSubsidyPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_addSubsidyPolicy'
import consumer_web_policyConfig_getSubsidyPolicyDetail from '@/lib/data-service/haolv-default/consumer_web_policyConfig_getSubsidyPolicyDetail'
import consumer_web_policyConfig_updateSubsidyPolicy from '@/lib/data-service/haolv-default/consumer_web_policyConfig_updateSubsidyPolicy'

export default {
    data() {
        return {
            templateSelect: null,
            templateIdList: [],
            id: 0,
            type: '',   //add 新增 edit 编辑
            loading: false,
            positionVoArr: [],
            templateList: [],
            sortList: [],
            switchBoolList: [],
        }
    },
    components: {
        PositionLevelChoose
    },
    created() {},
    mounted() {

    },
    activated() {
        this._getPosition();
        this.id = this.$route.query.id ? this.$route.query.id : null;
        this.loading = true;
        consumer_web_policyConfig_getUsingCityTemplateList()
            .then(res => {
                this.templateList = res.datas.templateList;
                if (this.id == null) {
                    this.templateList.forEach((item,index) => {
                        if (item.defaultType == 0) {
                            this.templateSelect = Number(item.templateId);
                            this.sortList = item.sortList;
                        }
                    });
                    if (this.templateSelect == null) {
                        this.templateSelect = Number(this.templateList[0].templateId);
                        this.sortList = this.templateList[0].sortList;
                    }
                    this.sortList.forEach((item) => {
                        item.$set(null,'price');
                    });
                } else {
                    let dataBox = {
                        companyId: '',
                        id: this.id,
                    };
                    this.loading = true;
                    consumer_web_policyConfig_getSubsidyPolicyDetail(dataBox).then(resOne => {
                        this.templateSelect = Number(resOne.datas.templateId);
                        this.sortList = resOne.datas.priceResultList;
                        this.positionVoArr.forEach((item,index) => {
                            if (resOne.datas.positionNames.indexOf(item.positionName) != -1) {
                                item.isEcho = true;
                            }
                        });
                    })
                }
            })
            .finally(() => {
                this.loading = false;
            });
    },
    deactivated() {

    },
    watch: {

    },
    computed: {},
    filters: {},
    methods: {
        //初始化使用职级组件
        _getPosition() {
            this.$refs.positionChoose.init({
                id: 0,
                type: 'add',
                businessType: 3
            })
        },
        // 城市价格--校验
        blurInput() {
            let switchBool = [];
            this.sortList.forEach((item) => {
                if (item.price) {
                    switchBool.push(true);
                } else {
                    switchBool.push(false);
                }
            });
            this.switchBoolList = switchBool;
        },
        // 更改模板
        changeSelect(value) {
            this.templateList.forEach((item,index) => {
                if (Number(item.templateId) == value) {
                    this.sortList = item.sortList;
                }
            });
        },
        // 取消按钮
        cancleEvectionTrainPolicy(){
            this.$router.go(-1)
        },
        // 提交按钮
        submitEvectionTrainPolicy() {
            let positionIds = [];
            this.positionVoArr.forEach((item) => {
                if (item.isEcho) {
                    positionIds.push(item.id);
                }
            });
            if (positionIds.length == 0) {
                this.$message({
                    message: '请填选择职级',
                    type: 'warning'
                });
                return;
            }

            let switchBool = [];
            let priceList = [];
            this.sortList.forEach((item) => {
                if (item.price) {
                    switchBool.push(true);
                } else {
                    switchBool.push(false);
                }
                priceList.push({
                  elseSort: item.elseSort,
                  price: item.price,
                  sortId: item.sortId,
                });
            });
            if (switchBool.indexOf(false) != -1) {
                this.switchBoolList = switchBool;
                this.$message({
                  message: '请填写出差补助信息',
                  type: 'warning'
                });
                return;
            }

            let dataBox = {
                addSubsidyPolicy: {
                    priceList: priceList,
                    templateId: this.templateSelect,
                },
                businessType: 5,
                companyId: '',
                id: 0,
                positionIds: positionIds,
            };
            this.loading = true;
            if (this.id == null) {
                consumer_web_policyConfig_addSubsidyPolicy(dataBox)
                  .then((res) => {
                    this.$message({
                      message: '保存成功',
                      type: 'success'
                    });
                    setTimeout(() => {
                      this.$router.push({
                        name: 'admin-evection-subsidy-policy',
                      })
                    }, 800);
                  })
                  .finally(() => {
                    this.loading = false;
                  })
            } else {
                dataBox.id = this.id;
                consumer_web_policyConfig_updateSubsidyPolicy(dataBox)
                  .then(res => {
                    this.$message({
                      message: '修改成功',
                      type: 'success'
                    });
                    setTimeout(() => {
                      this.$router.push({
                        name: 'admin-evection-subsidy-policy',
                      })
                    }, 800);
                  })
                  .finally(() => {
                    this.loading = false;
                  })
            }
        }
    }
}
